module.exports = [
  {
    id: 1,
    date: 4,
    name: 'Sheelagh W',
    text: 'We had really excellent service from Jack and his team in fitting wardrobes, shelves and an office in our Grade 1 listed apartment. Jack came up with some very helpful and practical solutions and nothing was too much trouble. I am able to wholeheartedly recommend Wow Interior Design. You will not be disappointed.',
    stars: 5,
  },
  {
    id: 2,
    date: 2,
    name: 'Ian Leonardi',
    text: 'Thanks to Jack and his team for the fantastic service they provided. The fitting and quality of our wardrobes is superb. Excellent customer that I highly recommend.',
    stars: 5,
  },
  {
    id: 3,
    date: 12,
    name: 'Christine King',
    text: 'Wow Interiors has certainly wowed us with our really fantastic new fitted bedroom units. Jack made the whole selection process easy and Matt our fitter worked without a break for three days to produce an amazing result. There was certainly no problems with our first contact call. Jack responded immediately and the whole design and ordering process was  extremely quick.  We can highly recommend this company.',
    stars: 5,
  },
  {
    id: 4,
    date: 12,
    name: 'Abby Jammeh',
    text: 'Excellent service, after getting quotes from all the main wardrobe companyies they were the only ones to recognise our original plan would have left us with little space, came up with a much better design, fitted to an excellent standard, so happy we found this company, would definitely use again! Thank-you!',
    stars: 5,
  },
  {
    id: 5,
    date: 72,
    name: 'Steve Mulrooney',
    text: 'A great service from start to finish. Help with designing our bedroom was outstanding. The guys who fitted the bedroom were great, and did a fantastic job. We couldn\'t be more please the bedroom now looks fantastic! I highly recommend them!',
    stars: 5,
  },
  {
    id: 6,
    date: 58,
    name: 'Michael Lee',
    text: 'Hi Jack, Many thanks for a project well designed and fitted - From start to finish the team worked quickly and efficiently and a pleasure to have on site. We are extremely pleased with the final results and have no hesitation in recommending \'WOW\' to anyone in the future. Many thanks. Mr & Mrs Lee',
    stars: 5,
  },
  {
    id: 7,
    date: 72,
    name: 'Sally Weaver',
    text: 'Having Researched the marketplace we chose WOW for many reasons from excellent product quality to the way they personally took us through all elements of the quote and how the build would take place. We spoke to all the major national brands offering these services, none seemed to match up to WOW. The fitters arrived on time every day, were extremely polite and hard working. I really cannot fault their work in any way. The wardrobes have transformed our bedrooms and I would happily recommend WOW to anyone looking for quality at an excellent price.',
    stars: 5,
  },
  {
    id: 8,
    date: 24,
    name: 'Kayli Gore',
    text: 'We are so pleased with our new fitted wardrobes. The quality of finish is superb, the fitter worked so hard and has done a fantastic job.',
    stars: 5,
  },
  {
    id: 9,
    date: 96,
    name: 'Joanne Nicky',
    text: 'First class service from start to finish. From the moment they turned up to my house to design me a bedroom to the moment they left after fitting it, was a delight.  They were kind and helpful and cleaned up afterwards so much, that i didn\'t need to get the hoover out when they left. Love my bedroom now, and love night time when i get to go up for the night. thank you guys!',
    stars: 5,
  },
  {
    id: 10,
    date: 4,
    name: 'Nicky Whipps',
    text: 'I\'ve recently had a very polite man from wow come measure up a walk in wardrobe for us and have to say he was very helpful, and showed me a few alternative ideas that I hadn\'t thought about. He was around for a little over an hour, where he gave me a design of what our bedroom would look like, and a breakdown of the cost so i could see exactly how much it will cost, and where the money was going to (furniture and fitting). When it was time to finish, he left without the usual salesmen tactic of staying until you signed on the dotted line. I have already recommended them to friends, and have left a review on here because I would recommend them to everyone who is looking for a fitted bedroom that doesn\'t want to get ripped off with an over priced fitted bedroom. It\'s a shame the review only goes to 3, because I would give them 10 out of 10.',
    stars: 5,
  },
  {
    id: 11,
    date: 24,
    name: 'KATE LETTERIELLO',
    text: 'Friendly and approachable, a really nice team of people!',
    stars: 5,
  },
  {
    id: 12,
    date: 1,
    name: 'Natalie Jackson',
    text: 'Amazing service from the team from design through to installation. The quality of the wardrobes are first class and installed to a very high specification. I would thoroughly recommend the team at Wow design and will certainly be a returning customer.',
    stars: 5,
  },
  {
    id: 13,
    date: 1,
    name: 'Lyn Hedgecock',
    text: 'We were very specific about what we wanted to make the best use of the space we had.  During our first meeting with Jack we looked at a variety of options and although what we finally decided on was something that was not the norm it was not an issue, the service really is bespoke.  I think the thing i liked about that first meeting was there was no big sales talk. No special offers if you buy now type of thing.  We had seen 3 other companies and was fed up with the patter at this point. The fitter Jegor was absolutely excellent.  He was so particular about every part of the build. Uneven walls and ceiling caused a few issues but looking at the finished result you would never know.  He left everything so clean and tidy and we are delighted with the end result.  Would highly recommend this company and I\'m sure we will be back to them in the future.',
    stars: 5,
  },
];
