import React from 'react';
import PropTypes from 'prop-types';

import { FaStar } from 'react-icons/fa';

import reviewItemStyles from './reviewItem.module.css';

const ReviewItem = (props) => {
  const { review } = props;
  const { name, text, stars } = review;
  let reviewText = text;

  if (text.length > 250) {
    const splitText = text.split('.');
    const [initialText] = splitText;
    reviewText = `${initialText}...`;
  }

  return (
    <div className={reviewItemStyles.reviewItemContainer}>
      <div className={reviewItemStyles.reviewItemText}>
        <i>{`"${reviewText}"  -`}</i>
        <span className={reviewItemStyles.reviewItemName}>{name}</span>
      </div>
      <div className={reviewItemStyles.reviewItemStarContainer}>
        {[...Array(stars)].map((e, index) => {
          const key = `star_${index}`;
          return (
            <div key={key} className={reviewItemStyles.reviewItemStar}>
              <FaStar />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewItem;

ReviewItem.propTypes = {
  review: PropTypes.object.isRequired,
};
