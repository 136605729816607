import React from 'react';

import { OutboundLink } from 'gatsby-plugin-gtag';

import MapComponent from './mapComponent';
import Review from './reviewComponent';
import AboutLocation from './aboutLocationComponent';

import aboutStyles from './about.module.css';
import processStyles from './process.module.css';

import Locations from '../data/locations';

export default () => {
  const groupSplit = 3;
  const locationInterval = Locations.length / groupSplit;
  const locationGroups = [];
  let selectStart = 0;
  let selectEnd = locationInterval;
  for (let i = 0; i < groupSplit; i += 1) {
    const locationSet = Locations.slice(selectStart, selectEnd);
    locationGroups.push(locationSet);
    selectStart += locationInterval;
    selectEnd += locationInterval;
  }

  return (
    <div className={aboutStyles.aboutContainer}>
      <section className={aboutStyles.aboutHeaderContainer}>
        <div className={aboutStyles.aboutHeaderText}>
          <h1>About Us</h1>
          <p>
            Based from our showroom in Folkestone, the Wow Interior Design team happily travel
            to homes across Kent, Sussex and South London to quote, design and install
            fitted bedrooms, wardrobes, under stairs storage, kitchens, offices, libraries,
            cinema rooms and much more.
          </p>
          <p>
            Not everything available is listed on our website, so if you are looking for something
            specific, get in touch to discuss your bespoke requirements!
          </p>
        </div>
      </section>
      <div className={aboutStyles.aboutMapContainer}>
        <MapComponent />
      </div>
      <div className={aboutStyles.aboutMapLinkContainer}>
        <OutboundLink className={aboutStyles.aboutMapLink} href="https://goo.gl/maps/Ah2u3mYFdTEdvrMv7" title="view on google maps" target="_blank" rel="noopener noreferrer">
          View or get directions on google maps
        </OutboundLink>
      </div>
      <section className={aboutStyles.aboutDetailsContainer}>
        <div>Suite 4</div>
        <div>Mottis Business Centre</div>
        <div>Cheriton High Street</div>
        <div>Folkestone</div>
        <div>Kent</div>
        <div>CT19 4QJ</div>
      </section>
      <section>
        <div className={processStyles.processHeaderContainer}>
          <div className={processStyles.processHeaderLine} />
          <div className={aboutStyles.aboutHeader}>Which Areas Do We Cover</div>
          <div className={processStyles.processHeaderLine} />
        </div>
        <div className={aboutStyles.aboutCoverContainer}>
          <p>
            If you have a new fitted furniture project where you are looking for a complete
            design & installation service contact us now. We can provide design inspiration & offer
            our years of experience to give you a free no obligation quotation and project manage
            the installation of your new fitted furniture from start to finish.
          </p>
          <p>
            Some of the places Wow Interiors design and install new
            fitted bedrooms, wardrobes, under stairs storage, kitchens, offices, libraries
            & lounge media units to
          </p>
          <div className={aboutStyles.aboutLocationGroups}>
            {locationGroups.map((locationGroup, index) => {
              const key = `location_${index}`;
              return (
                <AboutLocation key={key} locationGroup={locationGroup} />
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <div className={processStyles.processHeaderContainer}>
          <div className={processStyles.processHeaderLine} />
          <div className={aboutStyles.aboutHeader}>What Our Customers Say</div>
          <div className={processStyles.processHeaderLine} />
        </div>
        <div className={aboutStyles.aboutReviewContainer}>
          <Review
            showAll
            navColor="#343434"
            shadow={false}
          />
        </div>
      </section>
    </div>
  );
};

// render() {
//   return (
//     <Map
//       id="myMap"
//       options={{
//         center: { lat: 41.0082, lng: 28.9784 },
//         zoom: 8
//       }}
//       onMapLoad={map => {
//         var marker = new window.google.maps.Marker({
//           position: { lat: 41.0082, lng: 28.9784 },
//           map: map,
//           title: 'Hello Istanbul!'
//         });
//       }}
//     />
//   );
// }
