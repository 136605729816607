import React from 'react';

import Layout from '../components/layout';
import Menu from '../components/menuComponent';
import About from '../components/aboutComponent';

export default () => (
  <Layout
    title="All you need to know about Wow Interior Design | Wow Interior Design"
    description="Find out where are we located, The areas we cover in Kent & surrounding areas & what our customer's think about our work?"
  >
    <Menu />
    <About />
  </Layout>
);
