import React from 'react';
import PropTypes from 'prop-types';

import { startCase } from 'lodash';

import aboutStyles from './about.module.css';

const AboutLocation = (props) => {
  const { locationGroup } = props;
  return (
    <div className={aboutStyles.locationGroup}>
      {locationGroup.map((location) => (
        <p key={`location_${location.id}`}>{startCase(location.name)}</p>
      ))}
    </div>
  );
};

export default AboutLocation;

AboutLocation.propTypes = {
  locationGroup: PropTypes.array.isRequired,
};
