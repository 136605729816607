module.exports = [
  {
    id: '1',
    name: 'sevenoaks',
    link: '',
  },
  {
    id: '2',
    name: 'chatham',
    link: '',
  },
  {
    id: '3',
    name: 'dartford',
    link: '',
  },
  {
    id: '4',
    name: 'maidstone',
    link: '',
  },
  {
    id: '5',
    name: 'faversham',
    link: '',
  },
  {
    id: '6',
    name: 'sandwich',
    link: '',
  },
  {
    id: '7',
    name: 'hythe',
    link: '',
  },
  {
    id: '8',
    name: 'sittingbourne',
    link: '',
  },
  {
    id: '9',
    name: 'gillingham',
    link: '',
  },
  {
    id: '10',
    name: 'sidcup',
    link: '',
  },
  {
    id: '11',
    name: 'canterbury',
    link: '',
  },
  {
    id: '12',
    name: 'ashford',
    link: '',
  },
  {
    id: '13',
    name: 'dover',
    link: '',
  },
  {
    id: '14',
    name: 'headcorn',
    link: '',
  },
  {
    id: '15',
    name: 'tonbridge',
    link: '',
  },
  {
    id: '16',
    name: 'whitstable',
    link: '',
  },
  {
    id: '17',
    name: 'broadstairs',
    link: '',
  },
  {
    id: '18',
    name: 'rochester',
    link: '',
  },
  {
    id: '19',
    name: 'tunbridge-wells',
    link: '',
  },
  {
    id: '20',
    name: 'folkestone',
    link: '',
  },
  {
    id: '21',
    name: 'herne-bay',
    link: '',
  },
  {
    id: '22',
    name: 'lenham',
    link: '',
  },
  {
    id: '23',
    name: 'gravesend',
    link: '',
  },
  {
    id: '24',
    name: 'deal',
    link: '',
  },
];
