import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-gtag';

import ReviewItem from './reviewItem';

import reviewStyles from './review.module.css';

import Reviews from '../data/reviews';

const ReviewContainer = (props) => {
  const {
    showAll, navColor, shadow, shadowOpacity,
  } = props;
  const gLink = 'https://search.google.com/local/reviews?placeid=ChIJM6Dd_K_G3kcRzDUa3scmGMc';

  const containerStyle = {
    color: navColor,
  };

  if (!showAll) {
    containerStyle.textShadow = `0px 0px 20px ${navColor === '#343434' ? 'white' : 'black'}`;
  }

  if (shadow && !showAll && navColor !== '#343434') {
    containerStyle.backgroundColor = `rgb(52, 52, 52, ${shadowOpacity})`;
    containerStyle.boxShadow = `0 0 15px 10px rgba(52, 52, 52, ${shadowOpacity})`;
  }

  // if (shadow && !showAll && navColor !== '#343434') {
  //   containerStyle.backgroundColor = 'rgb(120, 130, 140, 0.8)';
  //   containerStyle.boxShadow = '0 0 15px 10px rgb(120, 130, 140, 0.7)';
  // }

  const reviews = showAll ? Reviews : [Reviews[Math.floor(Math.random() * Reviews.length)]];
  const cssContainer = showAll ? 'reviewShowAllContainer' : 'reviewContainer';
  return (
    <div className={reviewStyles[cssContainer]} style={containerStyle}>
      {reviews.map((review, index) => {
        const key = `$review_${index}`;
        return (
          <div key={key} className={showAll ? reviewStyles.reviewItemShowAllContainer : ''}>
            <ReviewItem
              review={review}
            />
          </div>
        );
      })}
      <div className={reviewStyles.reviewLink}>
        <span className={reviewStyles.reviewLinkHeader}>{'From Google Reviews - '}</span>
        <OutboundLink href={gLink} target="_blank" rel="noopener noreferrer" title="reviews">View all</OutboundLink>
      </div>
      {showAll
        ? <hr className={reviewStyles.reviewEndLine} />
        : null}
    </div>
  );
};

export default ReviewContainer;

ReviewContainer.defaultProps = {
  shadowOpacity: '0.4',
};

ReviewContainer.propTypes = {
  showAll: PropTypes.bool.isRequired,
  navColor: PropTypes.string.isRequired,
  shadow: PropTypes.bool.isRequired,
  shadowOpacity: PropTypes.string,
};
