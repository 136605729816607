import React from 'react';

import mapStyles from './map.module.css';
import logo from '../data/images/wow_favicon_with_pointer.png';

class MapComponent extends React.Component {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();
    this.init = this.init.bind(this);
  }

  componentDidMount() {
    if (window.google) {
      this.init();
      return;
    }
    //   const script = document.createElement('script');
    //   s.type = 'text/javascript';
    //   s.src = 'https://maps.google.com/maps/api/js?key=YOUR_API_KEY';

    //   const x = document.getElementsByTagName('script')[0];
    //   x.parentNode.insertBefore(s, x);
    //   // Below is important.
    //   // We cannot access google.maps until it's finished loading
    //   s.addEventListener('load', (e) => {
    //     this.onScriptLoad();
    //   });
    // } else {
    //   this.onScriptLoad();
    // }

    // const ApiKey = 'AIzaSyCWT1vJMQRxzZ7MrLI3oSDOSskLiHfkq-4';
    const ApiKey = 'AIzaSyD8fZmbQDgSGI-3IT3gJf1OVX2gLBCQv6o';
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      this.init();
    });

    document.body.appendChild(script);
  }

  init() {
    const options = {
      center: { lat: 51.090235704928844, lng: 1.125304857249706 },
      zoom: 15,
      styles: [
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    };

    const map = new window.google.maps.Map(
      this.mapRef.current,
      options,
    );
    // this.props.onMapLoad(map);

    const marker = new window.google.maps.Marker({
      position: { lat: 51.090262, lng: 1.122036 },
      title: 'Wow Interior Design',
      animation: window.google.maps.Animation.DROP,
      icon: logo,
    });

    marker.setMap(map);
  }

  render() {
    return (
      <div
        className={mapStyles.mapContainer}
        ref={this.mapRef}
      />
    );
  }
}

export default MapComponent;

// render() {
//     return (
//       <Map
//         id="myMap"
//         options={{
//           center: { lat: 41.0082, lng: 28.9784 },
//           zoom: 8
//         }}
//         onMapLoad={map => {
//           var marker = new window.google.maps.Marker({
//             position: { lat: 41.0082, lng: 28.9784 },
//             map: map,
//             title: 'Hello Istanbul!'
//           });
//         }}
//       />
//     );
//   }

// componentDidMount() {
//     // Once the Google Maps API has finished loading, initialize the map
//     this.getGoogleMaps().then((google) => {
//       const uluru = {lat: -25.363, lng: 131.044};
//       const map = new google.maps.Map(document.getElementById('map'), {
//         zoom: 4,
//         center: uluru
//       });
//       const marker = new google.maps.Marker({
//         position: uluru,
//         map: map
//       });
//     });
//   }

//   render() {
//     return (
//       <div>
//         <h1>Contact</h1>
//         <div id="map" style={{width: 400, height: 300}}></div>
//       </div>
//     )
//   }
